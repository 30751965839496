import { combineReducers } from 'redux'
import { reducer as currentReducer } from './slices/current'
import { reducer as authReducer } from './slices/auth'
import { reducer as notificationReducer } from './slices/notification'
import { reducer as uiReducerCatalog } from './slices/ui'
import { reducer as profileReducer } from './slices/profile'
import { reducer as designReducer } from './slices/design'
import { reducer as appointmentsReducer } from './slices/appointments'
import { reducer as usersReducer } from './slices/users'
import { reducer as catalogReducer } from './slices/catalog'
import { reducer as passCodeUsersReducer } from './slices/passCodeUsers'
import { reducer as bookmarksReduder} from './slices/bookmarks'
//import { profileReducer } from './reducers/profile'
import { containerReducer} from './reducers/container'  // @audit info da rimuovere?
import { uiReducer} from './reducers/ui'
import { reducer as experimentsReducer } from './slices/experiments'
import { connectRouter } from 'connected-react-router'

export const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  current: currentReducer,
  auth: authReducer,
  notification: notificationReducer,
  ui_catalog: uiReducerCatalog,
  ui: uiReducer,
  profile: profileReducer,
  design: designReducer,
  appointments: appointmentsReducer,
  users: usersReducer,
  catalog: catalogReducer,
  containers: containerReducer, // @audit info da rimuovere?
  experiments: experimentsReducer,
  passCodeUsers: passCodeUsersReducer,
  bookmarks: bookmarksReduder
})