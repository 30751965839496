import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Button, Progress, ButtonDropdown,
  CardTitle, CardHeader, CardBody, CardFooter, ModalHeader, Modal, ModalBody, DropdownToggle, DropdownMenu, DropdownItem,
  FormGroup, Label, Input, NavItem, Nav, NavLink,
  Container, Col, Row, Card, ModalFooter
} from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import { push } from 'connected-react-router';
import * as UiActions from '../store/actions/ui';
import { FaSave, FaUpload, FaTrashAlt } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { IconContext } from "react-icons";
import 'react-block-ui/style.css';
import moment from 'moment';
import RialeTimelineViewer from '../components/RialeTimelineViewer';
import { tracks } from '../components/Timeline/FakeItems';
import { RouteConst } from '../consts/common';
import { BsPlusCircle, BsInfoCircle } from "react-icons/bs";
import {RiExternalLinkFill} from "react-icons/ri"
import { FiEdit } from 'react-icons/fi'
import { useParams, Link } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import useUndoableState from "../components/UndoRedo";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Footer } from '../header';

// import * as ContentSelectors from '../store/selectors/content';
import * as UiSelectors from '../store/selectors/ui';

import { selectors as AuthSelectors } from '../store/slices/auth'
import { selectors as ExperimentsSelector, actions as ExperimentsActions } from '../store/slices/experiments'
import hash from "object-hash";
import {

  StyledInlineErrorMessage,
  StyledButton
} from "../styles/styles";

import { useTranslation } from 'react-i18next';
import { Header } from '../header';
import { Content } from '../components/Content';
import { IconButton } from '@material-ui/core';
const defTimelineStart = moment().startOf("day").add(10, "hours")
const defTimelineDuration = null;

export const TextAreaField: React.FC = (props) => {
  return (
    <Field component="textarea" rows={3} {...props} />
  )
}

export const TimelineDetail: React.FC = () => {
  const uiStatus = useSelector(UiSelectors.getLoadingStatus)
  const UiErrors = useSelector(UiSelectors.getErrors)
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });

  const ExperimentSchema = Yup.object().shape({
    title: Yup.string()
      .min(4, t("Campo troppo corto!"))
      .max(50, t("Campo troppo lungo!"))
      .required(t('Campo obbligatorio')),
    description: Yup.string()
    // .required('Descrizione obbligatoria')
  });


  const isStudent = useSelector(AuthSelectors.isStudent);
  const [currentPublicExperimentLink, setCurrentPublicExperimentLink] = useState(null) as any;
  const [lastUpdateDate, setLastUpdateDate] = useState(null) as any;
  const [lastPublishDate, setLastPublishDate] = useState(null) as any;
  //const [lastEditLocation, setLastEditLocation] = useState(null) as any;
  const [publishButtonEnabled, setPublishButtonEnabled] = useState(!isStudent)
  let { id } = useParams() as any;
  const currentExperiment = useSelector(ExperimentsSelector.getExperiment(id));
  const [savedExperimentMD5, setSavedExperimentMD5] = useState(currentExperiment == null ? null : hash(currentExperiment));
  
  
  //console.log(`Caricato experiment con id ${id}:`, currentExperiment);

  let saveButtonStatus = useSelector(UiSelectors.getSaveButtonStatus)
  let publicExperiments = useSelector(ExperimentsSelector.getPublicExperiments)

  // sezioni dell'esperimento (corrisponde ai vari tab)
  const sections = useSelector(ExperimentsSelector.getExperimentSections(id));
  //console.log("sezioni:", sections && sections[0] && sections[0].items);
  React.useEffect(() => {
    //console.log("TimelineDetail!");
    dispatch(ExperimentsActions.willLoadExperiments());
  }, [])

  React.useEffect(() => {
    //console.log("TimelineDetail!");
    dispatch(ExperimentsActions.willLoadPublicExperiments(id));
  }, [id])



  React.useEffect(() => {
    //console.log("Public experiment useEffect");
    if (id == null) {
      setCurrentPublicExperimentLink(null);
    }
    else {
      const myPublicExperiments = publicExperiments[id];
      //console.log("myPublicExperiments", myPublicExperiments);
      const lastPublicExpID = myPublicExperiments != null && myPublicExperiments.length>0 && myPublicExperiments.slice(-1)[0];
      if (lastPublicExpID) {
        //const publicLinkUrl = `${RouteConst.Public}/${id}_${lastPublicExpID.experiment}` as any;
        const publicLinkUrl = `${RouteConst.Public}/${lastPublicExpID.experiment}` as any;
        //console.log("Impostazione url pubblico:", publicLinkUrl);
        setCurrentPublicExperimentLink(publicLinkUrl);
        // la data di ultima pubblicazione si riferisce alla data della versione di timeline pubblicata
        // Il pulsante di pubblicazione va disattivato solo nel caso sia stato fatto almeno un salvataggio dopo il caricamento della pagina
        // altrimenti non si hanno sufficienti elementi per capire se la versione correntemente pubblicata
        // sia allineata con la versione privata della timeline
        setLastPublishDate(lastUpdateDate);
        if (lastUpdateDate!=null)
         {
          setPublishButtonEnabled(false);
         }
      }
      else
        setCurrentPublicExperimentLink(null);
    }

  }, [publicExperiments, id])



  const {
    state: sectionsState,
    setState: setSectionsState,
    resetState: resetSectionsState,
    index: sectionsStateIndex,
    lastIndex: sectionsStateLastIndex,
    goBack,
    goForward,
  } = useUndoableState({ "sections": sections, "activeTab": 0 });

  React.useEffect(() => {
    //console.log(`sections updated (activeTab:${activeTab}):`, sections);
    updateSectionsState();
    //console.log("sections updated UNDO-REDO called sections sectionsState->", sectionsState);
  }, [sections]);



  React.useEffect(() => {
    //console.log(`sections updated index`, sectionsStateIndex,);
    if (sectionsState != null && sectionsState["sections"].length > 0) {
      dispatch(ExperimentsActions.didSetSections(
        { sections: sectionsState["sections"], id }
      ));
      // mi sposto nel tab che era attivo in corrispondenza della modifica corrente
      toggleNav(sectionsState["activeTab"]);
      //console.log(`sections updated UNDO-REDO sectionsStateIndex (${sectionsStateIndex}) sectionsState->`, sectionsState);
    }

  }, [sectionsStateIndex]);


  const updateSectionsState = () => {
    if (sections != null && sections.length > 0) {
      // se le sezioni erano vuote nell'history ero ancora in fase di caricamento 
      // dell'esperimento...in tal caso resetto lo stato, altrimenti aggiungo
      // lo stato alla history corrente

      if (sectionsState["sections"].length < 1) {
        //console.log("sections updated UNDO-REDO - RESET -");
        resetSectionsState({ "sections": sections, "activeTab": 0})
      }
      else {
        // aggiorno la history solo se ho un valore accettabile
        setSectionsState({ "sections": sections, "activeTab": activeTab });
        //console.log("sections updated in history:",sections, sectionsState);
      }
    }
    // faccio in modo che ci sia sempre un tab valido selezionato
    if (sections != null && sections[activeTab] == null) {
      toggleNav("0");
    }
  }

  const canUndo = sectionsStateIndex > 0;
  const canRedo = sectionsStateIndex < sectionsStateLastIndex; // test < =>  <=

  const undo = () => {
    //console.log("sections updated UNDO-REDO called UNDO:", canUndo, sectionsState);
    if (canUndo) {
      goBack();
      //dispatch(UiActions.saveButtonAction("TO SAVE"));
      //console.log("sections updated UNDO-REDO called UNDO sectionsState->", sectionsState);
    }
  }

  const redo = () => {
    //console.log("sections updated UNDO-REDO ---> called REDO:", canRedo, sectionsState);
    if (canRedo) {
      goForward();
      //dispatch(UiActions.saveButtonAction("TO SAVE"));
    }
  }

  React.useEffect(() => {
    //console.log("Save button status:", saveButtonStatus)
    if (saveButtonStatus.text == "TO SAVE") {
      window.onbeforeunload = () => ""
    } else {
      window.onbeforeunload = null
    }
    return (() => {
      window.onbeforeunload = null
    })
  }, [saveButtonStatus])

  const dispatch = useDispatch();
  const timelineStart = null; //il default lo sceglie il componente sulla base del contenuto degli item// defTimelineStart
  const timelineDuration = defTimelineDuration;

  const handleItemChanged = (item: any, event: any) => {
    //console.log(`Intercettato evento su item-> ${item.title} di tipo ${event}`, item);
    //setLastEditLocation(item?.start_time); // non più implementato...crea problemi con l'undo/redo
    dispatch(ExperimentsActions.didUpdateItem(
      { id, event, item, sectionIndex: parseInt(activeTab) }
    ));
  }




  const [modal, setModal] = React.useState(false);
  const toggleModal = () => setModal(!modal);

  const [modalDelete, setModalDelete] = React.useState(false);
  const toggleModalDelete = () => setModalDelete(!modalDelete);
  const [modalDeleteAll, setModalDeleteAll] = React.useState(false);
  const toggleModalDeleteAll = () => {
    setModalDeleteAll(!modalDeleteAll);
    //window.close();
  }

  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalParams, setModalParams] = React.useState<any>({})
  const [timelineEditingEnabled, setTimelineEditingEnabled] = useState(true)
  const toggleModalEdit = (value: any) => {
    setModalEdit(!modalEdit);
    setModalParams(value)
    //console.log('in modal edit', value)
  };

  const [activeTab, setActiveTab] = useState('0');
  //console.log('changing tab....', activeTab)
  //console.log('sezione corrente:', sections);

  const toggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }


  // voglio salvare lo stato nella history
  // quando l'utente selezione un nuovo tab
  React.useEffect(() => {
    updateSectionsState();
  }, [activeTab])

  React.useEffect(() => {
    if (currentExperiment != null) {
      const newHash = hash(currentExperiment);
      //console.log("Esperimento cambiato: updateDate:",currentExperiment["updateDate"] );
      // se la data di aggiornamento è cambiata significa che ho appena salvato
      // l'esperimento e che non ho niente da risalvare fino alla prossima modifica
      if (currentExperiment["updateDate"]!=lastUpdateDate)
      {
        setLastUpdateDate(currentExperiment["updateDate"]);
        // salvo la hash della nuova versione dell'esperimento
        setSavedExperimentMD5(newHash);
        // disattivo il pulsante di salvataggio
        dispatch(UiActions.saveButtonAction("NO CHANGES")); 
        // è stata salvata una nuova versione della timeline
        // potenzialmente pubblicabile

        // Abilito il pulsante solo nel caso l'utente correntemente loggato NON sia uno studente,
        // in quanto non autorizzato
        if (!isStudent)
          {
            setPublishButtonEnabled(true);
          }
        
        return;
      }
      // se sono qua significa che ho fatto almeno una modifica dopo il salvataggio (eventualmente ripristinata con undo/redo)
      //console.log("Esperimento modificato:current:" , newHash, savedExperimentMD5, currentExperiment);
      if (savedExperimentMD5 == null) { setSavedExperimentMD5(newHash) }
      else  {
        
        dispatch(UiActions.saveButtonAction((newHash != savedExperimentMD5) ? "TO SAVE":"NO CHANGES")); // dispatch(UiActions.saveButtonAction("NO CHANGES"))
        // il pulsante per la pubblicazione è attivo solo se la versione correntemente in mostra corrisponde a quella
        // salvata in memoria.
        // Aggiungo il controllo che non abilita il pulsante nel caso la versione correntemente salvata della
        // timeline non sia stata già pubblicata. Se non ho sufficienti elementi per capire se la versione
        // correntemente salvata sia stata pubblicata (lastPublishDate==null), abilito il pulsante
        // Inoltre ci si deve accertare che l'utente autenticato non sia uno studente in quanto non autorizzato a pubblicare
        setPublishButtonEnabled(!isStudent && newHash == savedExperimentMD5 && (lastPublishDate==null || lastUpdateDate!=lastPublishDate));
      }
    }

  }, [currentExperiment])



  const confirmToggleNav = (tab: React.SetStateAction<string>) => {
    if (activeTab == tab) return;
    toggleNav(tab);
  }


  const saveTimeline = () =>
  {
    if (canEditExperiment && saveButtonStatus.text == "TO SAVE")
    {
      dispatch(ExperimentsActions.willUpdateExperiment(currentExperiment));
      //console.log(`Saving with new Experiment`);
    }
  }


 //let ownerID = ''
 // const userID = useSelector(ProfileSelectors.getProfile).sub
let canEditExperiment = true //ownerID == userID

  const dropDownExperiments = sections.map((value: any, i: number) => {
    return (
      <React.Fragment key={i}>
        <NavItem key={i} data-for={`tab_${i}`} data-tip={`${value.description}`}>
          <NavLink
            style={activeTab === `${i}` ?
              { fontWeight: "bold", cursor: "pointer", color: "#007bff", background: "#EEEEEE" } :
              { cursor: "pointer", color: 'green', background: 'white' }}


            onClick={() => {
              confirmToggleNav(`${i}`);
            }}
          >
            {value.title}{'   '}
            <>
              {(activeTab == `${i}` && canEditExperiment) ? (
                <FiEdit color='blue' onClick={() => { toggleModalEdit(value) }}></FiEdit>
              ) : (<></>)}
            </>
          </NavLink>
        </NavItem>
        <ReactTooltip id={`tab_${i}`} />
      </React.Fragment>
    )
  })

  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  //console.log("Valore di sections:", sections);
  return (
    <>
      <Header className="mb-0 text-white" section="Timeline" showMenu={false} />
      <Content fluid={true} className="pt-3" >
      <KeyboardEventHandler
          handleKeys={['alt+s']}
          onKeyEvent={(key, e) => 
          {
            //console.log(`KEY EVENT:do something upon keydown event of ${key}`);
           if (key=="alt+s")
            {
              //console.log("KEY EVENT:Save")
              saveTimeline();
            }
           
          }}
          />
        <Row className="m-0" style={{ height: 'calc(100% - 22px)' }}>
          <Col xs="12" style={{ height: '100%' }}>
            <Card className="mb-4" style={{ height: '100%', border:"0px solid #000", borderColor: "#007bff" }}>

              <CardHeader style={{
                backgroundColor: "#007bff",
                borderColor: "#007bff",
                paddingBottom: 0,
                color: 'white'
              }}>
                <CardTitle tag="h5">{currentExperiment != null ?
                <div>
                  <span style={{ marginRight:"8px"}}>{`${currentExperiment["title"]}`}</span>
                 
                  <IconContext.Provider  value={{ color: 'white'}}>
                          <BsInfoCircle style={{marginBottom:"4px"}} data-for="timelineInfo" data-place="right" 
                          data-tip={currentExperiment.description} size={'1.0em'} />
                    </IconContext.Provider>
                   
                    <ReactTooltip 
                    type="light"
                    id="timelineInfo" />
                    
                </div> 
                : t("Caricamento in corso...")
                  }

                  {canEditExperiment && 
                    <div style={{marginTop:"-30px"}}>
                      
                     <IconButton className="pull-right"
                     data-place="top" data-for="saveExperiment" data-tip={`${t("vai_a_risorsa_pubblica")}`}
                     >
                        <IconContext.Provider value={{ color: `${currentPublicExperimentLink ? 'white' : 'grey'}`}}>
                        <Link to={currentPublicExperimentLink || ""} target="_blank">
                        <RiExternalLinkFill style={{paddingBottom:"0px", marginTop:"-10px"}}size={'1.0em'} />
                        </Link>
                        </IconContext.Provider>
                      </IconButton>
                      
                      { timelineEditingEnabled &&
                      <IconButton className="pull-right" disabled={!publishButtonEnabled}
                        data-for="publishExperiment" data-tip={t("pubblica l'esperimento")}

                        onClick={() => {
                          //console.log("dispatch willPublishExperiment su ID:", id);
                          dispatch(ExperimentsActions.willPublishExperiment({ experiment: id }))
                        }}
                      >
                        <IconContext.Provider value={{ color: `${publishButtonEnabled ? 'white' : 'grey'}`}}>
                          <FaUpload size={'0.8em'} />
                        </IconContext.Provider>
                      </IconButton>
                      }

                { timelineEditingEnabled &&
                      <IconButton className="pull-right" disabled={saveButtonStatus.text !== "TO SAVE"}
                        data-place="top" data-for="saveExperiment" data-tip={`${t("salva le modifiche")} (alt+s)`}
                        onClick={() => {
                          saveTimeline();
                        }

                        }>

                        <IconContext.Provider value={{ color: `${saveButtonStatus.text === "TO SAVE" ? 'yellow' : 'green'}` }}>
                          <FaSave size={'0.9em'} />
                        </IconContext.Provider>
                      </IconButton>
                      }

            { timelineEditingEnabled &&

                      <ButtonDropdown direction="left" isOpen={dropdownOpen} toggle={toggleDropdown}
                        className="pull-right">
                        <DropdownToggle style={{ backgroundColor: "#007bff" }} caret>
                          <FaTrashAlt color='white' size={'1.2em'} />
                        </DropdownToggle>
                        <DropdownMenu>
                          {
                            sections.length > 1 &&
                            <DropdownItem onClick={toggleModalDelete}>
                              {t("Elimina la sezione corrente", {"currentTab":
                              sections[activeTab]["title"]
                            })}</DropdownItem>
                          }

                          <DropdownItem onClick={() => { toggleModalDeleteAll() }}>{t("Elimina l'intero esperimento")}</DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                        }
                      <ReactTooltip id="saveExperiment" />
                      <ReactTooltip id="publishExperiment" />
                    </div>
                    
                    }

                </CardTitle>
              </CardHeader>
              <CardBody>
                {/* <div className="App"> */}
                <div>
                  <Nav tabs>
                    {dropDownExperiments}
                    {canEditExperiment ? (
                      <NavItem>
                        <NavLink style={{ cursor: 'pointer' }}
                          onClick={toggleModal}
                        >
                          <BsPlusCircle color='blue' size="1.5em" />
                        </NavLink>
                      </NavItem>
                    ) : (<></>)}
                  </Nav>
                  <div></div>
                </div>

                {(sections && sections[activeTab] != null) ? (
                  <div>
                    <Row>
                      <Col>
                        <Card body className="text-center">
                          {/* 
                          <CardHeader tag="h4">{sections[activeTab].title}:{sections[activeTab].description}</CardHeader>
                        */}
                        </Card>
                      </Col>
                    </Row>

                    { sections != null && sections.length > 0 && sections[activeTab] != null &&

                      <RialeTimelineViewer activeTab={activeTab}
                        id={id}
                        sections={sections}
                        tracks={tracks}
                        startDateTime={timelineStart}
                        // duration={timelineDuration}
                        onItemChanged={handleItemChanged}
                        title={sections[activeTab].title}
                        canEdit={canEditExperiment}
                        onUndo={() => undo()}
                        onRedo={() => redo()}
                        onTimelineEditingEnabled={(value:boolean)=>{
                          //console.log("setTimelineEditingEnabled to:", value);
                          setTimelineEditingEnabled(value)}}
                        canUndo={canUndo}
                        canRedo={canRedo}
                      />

                    }

                  </div>
                ) : (<></>)}

                <Modal isOpen={modalDelete} toggle={toggleModalDelete} >
                  <ModalHeader toggle={toggleModalDelete}>{t('elimina_esperimento', {"currentTab": sections[activeTab]?.title})}</ModalHeader>
                  <ModalBody>{t('confermare_elimina_esperimento',{"currentTab": sections[activeTab]?.title})}</ModalBody>
                  <ModalFooter>
                    <Button color="danger" style={{ float: "right" }}
                      onClick={() => {
                        dispatch(ExperimentsActions.didDeleteSection({ "id": id, "sectionIndex": parseInt(activeTab) }));
                        //dispatch(UiActions.saveButtonAction("TO SAVE"));
                        toggleNav("0");
                        //dispatch(ExperimentsActions.willUpdateExperiment(currentExperiment));
                        setModalDelete(!modalDelete);
                      }}>

                      {t('elimina')}

                    </Button>{' '}

                    <Button color="info" onClick={toggleModalDelete} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                  </ModalFooter>
                </Modal>

                <Modal isOpen={modalDeleteAll} toggle={toggleModalDeleteAll} >
                  <ModalHeader toggle={toggleModalDeleteAll}>{t('elimina_gruppo_esperimenti')}</ModalHeader>
                  <ModalBody>{t('confermare_elimina_gruppo_esperimenti')}</ModalBody>
                  <ModalFooter>
                    <Button color="danger" style={{ float: "right" }} onClick={() => {
                      dispatch(ExperimentsActions.willDeleteExperiment({ experiment: id }));
                      setModalDeleteAll(!modalDeleteAll);
                      dispatch(push(`/timeline/`));
                    }}>{t('elimina')}</Button>{' '}
                    <Button color="info" onClick={toggleModalDeleteAll} style={{ float: "right" }}>{t('cancel')}</Button>{' '}
                  </ModalFooter>
                </Modal>




                <Modal isOpen={modal} toggle={toggleModal} >
                  <ModalHeader toggle={toggleModal}>{t('aggiungi_esperimento')}</ModalHeader>
                  <ModalBody>
                    <Formik
                      initialValues={{
                        title: '',
                        description: ''
                      }}
                      validationSchema={ExperimentSchema}
                      validateOnBlur={true}
                      onSubmit={values => {
                        //console.log("exp values: ", values);

                        const section = {
                          "title": values.title, "description": values.description,
                          "items": []
                        }
                        dispatch(ExperimentsActions.didAddSection(
                          { section, sectionIndex: activeTab, id }
                        ));
                        setModal(!modal);
                        //dispatch(UiActions.saveButtonAction("TO SAVE"))
                      }}
                    >
                      {({ errors, touched, setFieldValue, values }) => (
                        <Container>
                          <Form name="create_experiment" method="post">
                            <FormGroup row>
                              <Label sm={3}>{t('titolo')}</Label>
                              <Col sm={9}>
                                <Input name="title" type="text" placeholder={t('titolo')} tag={Field} />
                                {errors.title && touched.title ?
                                  <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label sm={3}>{t('descrizione')}</Label>
                              <Col sm={9}>
                                <Input name="description" type="textarea" placeholder={t('descrizione')} tag={TextAreaField} />
                                {errors.description && touched.description ?
                                  <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col sm={9}>
                                <StyledButton block color="primary" type="submit" >{t('conferma_aggiunta_esperimento')}</StyledButton>{' '}
                              </Col>
                              <Col sm={3}>
                                <Button color="secondary" onClick={toggleModal}>{t('cancel')}</Button>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      )}
                    </Formik>
                  </ModalBody>
                </Modal>


                <Modal isOpen={modalEdit} toggle={toggleModalEdit} >
                  <ModalHeader toggle={toggleModalEdit}>{t('modifica_esperimento')}</ModalHeader>
                  <ModalBody>
                    <Formik
                      initialValues={{
                        title: modalParams.title,
                        description: modalParams.description
                      }}
                      validationSchema={ExperimentSchema}
                      validateOnBlur={true}
                      onSubmit={values => {
                        //console.log("section update values: ", values);
                        dispatch(ExperimentsActions.didUpdateSection({
                          "id": id, "title": values.title,
                          "description": values.description, "sectionIndex": parseInt(activeTab)
                        }))
                        //dispatch(UiActions.saveButtonAction("TO SAVE"))
                        setModalEdit(!modalEdit);
                      }}
                    >
                      {({ errors, touched, setFieldValue, values }) => (
                        <Container>
                          <Form name="edit_experiment" method="post">
                            <FormGroup row>
                              <Label sm={3}>{t('titolo')}</Label>
                              <Col sm={9}>
                                <Input name="title" type="text" tag={Field} />
                                {errors.title && touched.title ?
                                  <StyledInlineErrorMessage>{errors.title}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label sm={3}>{t('descrizione')}</Label>
                              <Col sm={9}>
                                <Input name="description" type="textarea" tag={TextAreaField} />
                                {errors.description && touched.description ?
                                  <StyledInlineErrorMessage>{errors.description}</StyledInlineErrorMessage> : null}
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Col sm={9}>
                                <StyledButton block color="primary" type="submit" >{t('conferma_modifica_esperimento')}</StyledButton>{' '}
                              </Col>
                              <Col sm={3}>
                                <Button color="secondary" onClick={toggleModalEdit}>{t('cancel')}</Button>
                              </Col>
                            </FormGroup>
                          </Form>
                        </Container>
                      )}
                    </Formik>
                  </ModalBody>
                </Modal>
                <div>
                  <Footer /> 
                </div>
              </CardBody>
              
            </Card>
          </Col>
        </Row>
      </Content>
    
      {/* </div> */}
      {/*  </BlockUi> */}
    </>

  );
}

export const UploadingModal = () => {
  const uploading = useSelector(UiSelectors.getUploadingState);
  const update_progress = useSelector(UiSelectors.getUpdateProgress);
  const [modal, setModalUploading] = React.useState(false);
  const uploadingToggle = () => setModalUploading(!uploading);
  const { t, i18n } = useTranslation('frontend', { useSuspense: false });
  //console.log('uploading statusss ', uploading)
  return (
    <Modal isOpen={uploading} toggle={uploadingToggle}  >
      <ModalHeader>{t('upload_in_corso')}</ModalHeader>
      <ModalBody>
        <div>
          <div className="text-center"><h2>{Math.trunc(update_progress)}%</h2></div>
          <Progress bar color="info" value={Math.trunc(update_progress)} style={{ width: '70%' }} />
        </div>
      </ModalBody>
    </Modal>

  );
}
export default UploadingModal