import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { selectors as CatalogSelectors } from '../store/slices/catalog'
import {actions as CatalogActions} from '../store/slices/catalog'
import { Badge } from 'reactstrap';
import moment from 'moment';
import i18n from '../i18n';
import { useParams } from 'react-router-dom'
export const IFrameCatalogPage = () => {

  const catalog: any = useSelector(CatalogSelectors.getFilteredCatalog(null));
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const lang = query.get("lang");

 /*
  useEffect(()=>{
    //console.log("iFrame changeLanguage");
    i18n.changeLanguage("it-IT" as any);
        moment.locale("it-IT");
  },[])
  */

  useEffect(()=>{
    //console.log("iFrame changeLanguage to:", lang);
    if (lang)
    {
      i18n.changeLanguage(lang as any);
      moment.locale(lang);
    }
  
  },[lang])

  useEffect(() => {
    // This will be now called when the locale is changed
    //console.log("iFrame RELOAD CATALOG -> RIALE-EU LANGUAGE SET TO:",i18n.language)
    //n.b: il passaggio della lingua corrente non è necessario in quanto
    // l'api di catalog leggera direttamente il valore corrente di i18m.language
    dispatch(CatalogActions.willLoadCatalog());

    }, [i18n.language])

  const renderPercorso = (percorso:any) => {
    return (
      <div key={`percorso_${percorso.id}`} style={{display:"flex", flexDirection:"column", marginBottom:"5px", marginTop:"5px"}}>
      <span style={{marginBottom:"5px", marginTop:"5px"}}><h5>{percorso.titolo}</h5> </span>
      <ul>
      {renderExperiments(percorso)}
      </ul>
     
      </div>
    )
  }
  
  const renderExperiments = (topic:any) => {
    return (topic.esperimenti.map((esperimento:any, index:number) =>{
      if (esperimento.type=="path") 
      {return renderPercorso(esperimento)}
      else return(
        <li key={`li_experiment_${esperimento.id}`}>
            <a target='_blank' href={`experiment/${esperimento.id}`}>{esperimento.titolo}
            {/*esperimento.live && <Badge style={{marginLeft:"5px"}} color='danger'>Live</Badge>*/}</a>
        </li>
     
      )
    }))
  }

  const renderCatalog = () =>
  {
      return catalog && catalog.map((topic:any,index:number)=>{
       // //console.log("iframe topic:", topic);
        return (
          <div key={`iframe_item_${index}`} style={{display:"flex", marginLeft:"10px", flexDirection:"column"}}>
              
              <h4>{topic.titolo}</h4>
              <ul>
              {renderExperiments(topic)}
              </ul>
              
          </div>)
         
      })
       
      
    }
  
  return (<div style={{fontFamily:"sans-serif", display:"flex", flexDirection:"column"}}>
     {catalog && renderCatalog()}
  </div>)
}