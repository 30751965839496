import { all } from 'redux-saga/effects'
import { sagas as authSagas } from './auth'
import { sagas as notificationSagas } from './notification'
import { sagas as profileSagas } from './profile'
import { sagas as designSagas } from './design'
import { sagas as remotesessionsSagas } from './remotesessions'
import { sagas as usersSagas } from './users'
import { sagas as catalogSagas } from './catalog'
import { sagas as passCodeUsersSagas } from './passCodeUsers'

import {doLoadContainerSagas} from './container';
import {sagas as experimentsSagas} from './experiments'

function* runAllSagas() {
  //console.log('in root saga')
  yield all([
    profileSagas(),
    notificationSagas(),
    authSagas(),
    designSagas(),
    remotesessionsSagas(),
    usersSagas(),
    catalogSagas(),
    doLoadContainerSagas(),  //@audit info da rimuovere?
    experimentsSagas(),
    passCodeUsersSagas()
  ])
}

export default runAllSagas;