
import React, {Component, createRef} from 'react';
import { FaVolumeMute, FaVolumeUp, FaPlay, FaPause } from 'react-icons/fa';
import {MdSubtitles} from 'react-icons/md';
import { BsPip } from "react-icons/bs";
import { MdHearing } from "react-icons/md";
import { IconContext } from "react-icons";
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from "react-tooltip";
import { RiFullscreenLine, RiFullscreenExitLine, RiRewindFill } from "react-icons/ri";
import { AiFillStepBackward, AiFillStepForward } from 'react-icons/ai'
import { withTranslation } from 'react-i18next';
import {Label, Badge} from 'reactstrap';
import moment from "moment";
import ThumbnailsProgress from './VideoThumbnailsProgress';


const bgColor = "#007bff"; // grey
const fgColor = "white";

class ControlsPanelNT extends Component
{
    inputRef = createRef();
    componentDidUpdate(prevProps, prevState) {

        if (prevProps.volume!=this.props.volume)
        {
            ReactTooltip.rebuild();
        }
    }

    render()
    {
        const {item, volume, muted,audioSolo,t, isFullscreen, timelineIsPlaying, areVideoLoading, onPlayTimeline,onPauseTimeline, subtitlesAvailable} = this.props;
        const audioSoloColor = (!audioSolo ? "white" : (muted ? "red" : "green"));

        const formattedPlayerSeconds = this.props.playedSeconds<10 ?
        `00:0${parseInt(this.props.playedSeconds)} / ${moment.duration(this.props.duration,"seconds").format("hh:mm:ss")}` :
        (
           this.props.playedSeconds<60 ?  
           `00:${parseInt(this.props.playedSeconds)} / ${moment.duration(this.props.duration,"seconds").format("hh:mm:ss")}` :
           `${moment.duration(this.props.playedSeconds, "seconds").format("hh:mm:ss")} / ${moment.duration(this.props.duration,"seconds").format("hh:mm:ss")}`
        )

        return ( <div ref={this.inputRef} style={{ position: 'absolute',
        bottom: `${isFullscreen ? 10:2}px`,
        right: "0px",
        backgroundColor:"darkgrey",
        opacity:"0.8",
        borderStyle: "",
        width:"100%",
        zIndex:200}} >
            {
                isFullscreen && (
                    <div style={{padding:"10px"}}>
                            <ThumbnailsProgress 
                            animated={timelineIsPlaying && areVideoLoading}
                            playedSeconds={this.props.playedSeconds} 
                            duration={this.props.duration} 
                            onDatetimeChangeRequest= {(newPos)=>{this.props.onDatetimeChangeRequest(newPos)}}
                            fullScreenDomNode={this.inputRef} item={item} />
                    </div>
                    
                )
            }

            {
           
           
                <IconButton onClick={()=> { 
                    timelineIsPlaying ? onPauseTimeline() : onPlayTimeline()
                
                }
                
                }  style={{
            backgroundColor:bgColor,
            opacity:'1',
            margin:'3px', padding:"10px"
            
           }}>
               <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                   {timelineIsPlaying ? 
                   <FaPause data-place="top" data-tip={t("tl:tip_timeline_pause")} /> :
                   <FaPlay data-place="top" data-tip={t("tl:tip_timeline_play")}  /> }
                  

               </IconContext.Provider>
         </IconButton>
            }

            {
               isFullscreen &&  
               <IconButton onClick={()=> {item!=null && this.props.onDatetimeChangeRequest(item.start_time)}}  style={{
                backgroundColor:bgColor, 
                opacity:'1',
                margin:'3px', padding:"10px"
                
               }}>
                   <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                       <RiRewindFill data-place="top" data-tip={t("")}  /> 
                      
                   </IconContext.Provider>
             </IconButton>

            }

        {   /*       
               isFullscreen &&  
               <IconButton onClick={()=> {item!=null && this.props.onDatetimeChangeRequest(
                moment(item.end_time).add(-0.1,"seconds"))}}  style={{
                backgroundColor:bgColor, 
                opacity:'1',
                margin:'3px', padding:"10px"
                
               }}>
                   <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                       <AiFillStepForward data-place="top" data-tip={t("")}  /> 
                      
                   </IconContext.Provider>
             </IconButton>

            */ }

          <IconButton onClick={()=> {this.props.onToggleMute()}}  style={{
            backgroundColor:"black", 
            opacity:'1',
            margin:'3px', padding:"10px"
            
           }}>
               <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                   {volume<=0 ? 
                   <FaVolumeMute data-place="top" data-tip={t("tl:tip_audioOff")} /> :
                   <FaVolumeUp data-place="top" data-tip={t("tl:tip_audioOn")}  /> }
                  

               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onToggleAudioSolo()}  style={{
            backgroundColor:`${audioSoloColor}`, 
            opacity:'1',
            margin:'3px', padding:"10px",
           }}>
               <IconContext.Provider value={{color: "black", size:"0.60em" , padding:"0px"}}>
                  <MdHearing data-place="top" data-tip={t("tl:tip_muteOthers")}  />
               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onFullScreenRequest()}  style={{
            backgroundColor:"black", 
            opacity:'1',
            margin:'3px', padding:"10px"
           }}>
               <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                   {
                       (this.props.isFullscreen ? 
                        <RiFullscreenExitLine data-place="top" data-tip={t("tl:tip_fullscreen_exit")} /> :
                        <RiFullscreenLine data-place="top" data-tip={t("tl:tip_fullscreen")} />
                        )
                   }
                 
               </IconContext.Provider>
         </IconButton>

         <IconButton onClick={()=> this.props.onTogglePip()}  style={{
            backgroundColor:"black", 
            margin:'3px', padding:"10px"
            
           }}>
               <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                   <BsPip data-place="top" data-tip={t("tl:tip_pip")} /> 
               </IconContext.Provider>
         </IconButton>

         {
           subtitlesAvailable &&
           (
            <IconButton onClick={()=> this.props.onToggleSubtitles()}  style={{
                backgroundColor:`${this.props.subtitles ? "green" : "red"}`, 
                margin:'3px', padding:"10px"
                
               }}>
                   <IconContext.Provider value={{color: "white", size:"0.60em" , padding:"0px"}}>
                       <MdSubtitles data-place="top" data-tip={t("tl:tip_subtitles")} /> 
                   </IconContext.Provider>
             </IconButton>
           )
         }
         <Badge  color="primary" style={{marginLeft:"10px"}}>
         {
          formattedPlayerSeconds
         }
         
         </Badge>
           
         
         <ReactTooltip place="top"/>
         </div>)
    }
}

const ControlsPanel = withTranslation()(ControlsPanelNT); 
export default ControlsPanel;